import styled from "styled-components";
import { MdKeyboardArrowRight, MdArrowForward,
            MdKeyboardArrowDown, MdArrowDownward,
            MdKeyboardArrowUp, MdArrowUpward,
            MdKeyboardArrowLeft, MdArrowBack,} from 'react-icons/md';

export const WhatWeDoContainer = styled.div`
    min-height: 100vh;
    background-color: #B9A792;
    position: relative;
`;

export const WhatWeDoCore = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;

    @media screen and (orientation:landscape) {
        flex-direction: row;
    }
`;

export const TeaserImage = styled.div`
    height: 25vh;
    background-image: url(${props => props.TeaserImg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-position-x: center;

    @media screen and (orientation:landscape) {
        height: 100vh;
        margin: 0 0;
        width: 40%;
        max-width: 400px;
        order: ${props => (props.pos === 'right' ? 1 : 0) };
    }
`;

export const ExplanationContainer = styled.div`
    width: 100%;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    @media screen and (orientation:landscape) {
        height: 100%;
        margin: auto auto;
    }
`;

export const Explanation = styled.div`
    color: white;
    margin: auto auto;
    width: 80%;
    max-width: 800px;
    align-items: center;
    text-align: center;

    @media screen and (orientation:landscape) {
        flex-basis: 100%;
    }
`;

export const BtnWrapper = styled.div`
    width: 200px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    align-items: center;
    margin-bottom: 100px;

    @media screen and (orientation:landscape) {
        margin-top: 100px;
    }
`;

export const TextHeadline = styled.div`
    color: #D09573;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 30px;

    @media screen and (orientation:landscape) {
        font-size: 2rem;
    }
`;

export const TextBody = styled.div`
    font-size: 1.2rem;
    margin-bottom: 30px;
    line-height: 1.5;

    @media screen and (orientation:landscape) {
        font-size: 1.5rem;
    }
`;

export const ArrowForward = styled(MdArrowForward)`
    font-size: 20px;
`;

export const ArrowRight = styled(MdKeyboardArrowRight)`
    font-size: 20px;
`;

export const ArrowDownward = styled(MdArrowDownward)`
    font-size: 20px;
`;

export const ArrowDown = styled(MdKeyboardArrowDown)`
    font-size: 20px;
`;

export const ArrowUpward = styled(MdArrowUpward)`
    font-size: 20px;
`;

export const ArrowUp = styled(MdKeyboardArrowUp)`
    font-size: 20px;
`;

export const ArrowBack = styled(MdArrowBack)`
    font-size: 20px;
`;

export const ArrowLeft = styled(MdKeyboardArrowLeft)`
    font-size: 20px;
`;