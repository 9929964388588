import styled from "styled-components";
import { Link } from 'react-scroll';
//import { Link as RLink } from 'react-router-dom';
import { HashLink as RLink } from 'react-router-hash-link';

export const Button = styled(Link)`
    width: 80px;
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#D9DED6' : '#D9DED6') };
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px') };
    color: ${({dark}) => (dark ? '#010606' : '#fff') };
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px') };
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#A84959' : '#A84959') };
    }
`;

export const RButton = styled(RLink)`
    width: 80px;
    border-radius: 50px;
    background: ${({primary}) => (primary ? '#D9DED6' : '#D9DED6') };
    white-space: nowrap;
    padding: ${({big}) => (big ? '14px 48px' : '12px 30px') };
    color: ${({dark}) => (dark ? '#010606' : '#fff') };
    font-size: ${({fontBig}) => (fontBig ? '20px' : '16px') };
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({primary}) => (primary ? '#D09573' : '#D09573') };
    }
`;