import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Impressum from './components/impressum/Impressum';
import Home from './components/Home';
import Datenschutz from './components/impressum/Datenschutz';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} exact />
        <Route path='/impressum' element={<Impressum />} exact />
        <Route path='/datenschutz' element={<Datenschutz />} exact />
      </Routes>
    </Router>
  );
}

export default App;
