export const main  = {
    TeaserImg: require('./images/roses.jpg'),
    BodyText: [ 'Denise & Gordon',
                'Wir heiraten',
                'und freuen uns diesen Tag mit Euch zu verbringen.',
                'Am 29. Juli 2023'],
};

export const location  = {
    TeaserImg: require('./images/ringe.jpg'),
    BodyText: [ 'Die Trauung findet statt um 13 Uhr',
                'Auf der HöhenBurg Stargard',
                'Burg 1, 17094 Burg Stargard',
                'Wir möchten mit Euch feiern',
                'Im Burggasthof "Zur Alten Münze"',
                'Ebenda '],
};

export const ablauf  = {
    TeaserImg: require('./images/uhr.jpg'),
    BodyText: [ '13:00 Trauung',
                '14:00 Uhr Sektempfang',
                '16:00 Uhr Kaffee und Kuchen',
                '18:30 Uhr Hochzeitsbuffet',
                '20:00 Uhr Party',
                '23:30 Uhr Anschnitt der Hochzeitstorte'],
};

export const rsvp  = {
    TeaserImg: require('./images/yes.jpg'),
    BodyText: [ 'Bitte gebt uns Rückmeldung',
                'bis zum 01.05.',
                'mit wie vielen Personen Ihr kommt.',
                'ja@deniseundgordon.de'],
};

export const geschenk  = {
    TeaserImg: require('./images/geschenk.jpg'),
    BodyText: [ 'Wir laden ein - Ihr werdet denken: "Was kann man nur dem Brautpaar schenken?".',
                'Unser Haushalt ist komplett, darum wäre es sehr nett,',
                'wenn ihr an unsere Flitterwochen denkt und Futter für das Reise-Sparschwein schenkt.']
};

export const zimmer  = {
    TeaserImg: require('./images/bett.jpg'),
    BodyText: [ 'Wir haben für Euch ein Kontingent an Zimmern im „Hotel zur Burg“ reserviert.',
                'Solltet Ihr ein Bettchen benötigen, bucht bitte selbstständig bis zum 30.04.',
                'unter dem Stichwort „Hochzeit Isaac“',
                '039603 - 2650'],
};

export const burg = {
    TeaserImg: require('./images/kopfstein.jpg'),
    BodyText: [ 'Die Burg Stargard ist eine Höhenburg aus dem Mittelalter. Wir bitten Euch dies bei der Schuhauswahl zu berücksichtigen.',
                'Außerdem bitten wir Euch zum Schutze der Kulturstätte auf Konfetti, Reis, Pyrotechnik oder ähnliches zu verzichten.']
};

export const beitrag  = {
    TeaserImg: require('./images/party.jpg'),
    BodyText: [ 'Natürlich freuen wir uns über Eure Beiträge zu unserer Feier.',
                'Bitte sprecht diese aber in jedem Fall bis zum 01.07. mit Matthias ab:',
                'party@deniseundgordon.de',
                '0179 - 5917275'],
};

export const end  = {
    TeaserImg: require('./images/herzen.jpg'),
    BodyText: ['All you need is love.'],
};