import React, { useState } from 'react';
import { Button } from '../ButtonElement';
import { Explanation, TeaserImage, TextBody, ExplanationContainer,
            BtnWrapper, WhatWeDoContainer, WhatWeDoCore,
            ArrowDownward, ArrowDown, ArrowUpward, ArrowUp } from './WhatWeDoStyling';

function WhatWeDo({TeaserImg, BodyText, ImgPos, id, nextSection}) {

    const [hoverD, setHoverD] = useState(false);

    const onHoverD = () => {
        setHoverD(!hoverD);
    }

    return (
        <WhatWeDoContainer>
            <WhatWeDoCore>
                <TeaserImage TeaserImg={TeaserImg} pos={ImgPos} id={id}/>
                <ExplanationContainer>
                    <Explanation>
                        {
                            BodyText.map((item) => {
                                return <TextBody>{item}</TextBody>
                            })
                        }
                    </Explanation>
                    <BtnWrapper>
                        <Button to={nextSection} 
                                onMouseEnter={onHoverD}
                                onMouseLeave={onHoverD}
                                primary='true'
                                dark='true'
                                smooth='true'
                                >
                            {   
                                nextSection === 'home' ?
                                
                                    hoverD ? <ArrowUpward /> : <ArrowUp />
                                :
                                    hoverD ? <ArrowDownward /> : <ArrowDown />
                            }
                        </Button>
                    </BtnWrapper>
                </ExplanationContainer>
            </WhatWeDoCore>
        </WhatWeDoContainer>
    )
}

export default WhatWeDo;
