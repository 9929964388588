import Footer from './footer/Footer';
import WhatWeDo from './whatwedo/WhatWeDo';
import { main, rsvp, location, ablauf, geschenk, zimmer, burg, beitrag, end } from '../Content';

const Home = () => {

    return (
        <>
            <WhatWeDo {...main} ImgPos='left' id='home' nextSection='location'/>
            <WhatWeDo {...location} ImgPos='right' id='location' nextSection='ablauf'/>
            <WhatWeDo {...ablauf} ImgPos='left' id='ablauf' nextSection='rsvp'/>
            <WhatWeDo {...rsvp} ImgPos='right' id='rsvp' nextSection='geschenk'/>
            <WhatWeDo {...geschenk} ImgPos='left' id='geschenk' nextSection='zimmer'/>
            <WhatWeDo {...zimmer} ImgPos='right' id='zimmer' nextSection='burg'/>
            <WhatWeDo {...burg} ImgPos='left' id='burg' nextSection='beitrag'/>
            <WhatWeDo {...beitrag} ImgPos='right' id='beitrag' nextSection='end'/>
            <WhatWeDo {...end} ImgPos='left' id='end' nextSection='home'/>
            <Footer />
        </>
    )
}

export default Home;