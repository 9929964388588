import styled from "styled-components";
import { HashLink as Link } from 'react-router-hash-link';

export const FooterContainer = styled.div`
    background-color: #A84959;
    padding-top: 25px;
    padding-bottom: 25px;
`;

export const FooterElement = styled(Link)`
    margin: 25px;
    color: white;
    font-size: 0.8rem;
    text-decoration: none;

    &:hover {
        transition: all 0.2s ease-in-out;
        color: darkgray;
    }

    @media screen and (max-width: 450px) {
        margin: 25px 15px;
    }
`;